<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader text="Заболевания (состояния)">
        <AddButton buttonText="Добавить" />
      </PageHeader>
      <TableWithSearch
        :headers="headers"
        :items="nosologies"
        :rowClickLink="rowClickLink"
      />
    </div>
    <div v-else>Нет заболеваний (состояний)</div>
  </DisplayLoading>
</template>

<script>
import AddButton from '@/components/buttons/AddButton.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import TableWithSearch from '@/components/common/TableWithSearch.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { ADMIN, NOSOLOGIA_SHORT, NOSOLOGIES } from '@/store/const/path'
import { API_NOSOLOGIES } from '@/store/const/apiPaths'

export default {
  components: {
    AddButton,
    PageHeader,
    DisplayLoading,
    TableWithSearch,
  },
  mixins: [routeParams, loadPageData],
  data() {
    return {
      loadUrl: API_NOSOLOGIES,
      errorMessage: `Не удалось загрузить список заболеваний (состояний)`,
      loadingMessage: 'Загружаю список заболеваний (состояний)',
      headers: [
        { value: 'name', text: 'Название', width: 800 },
        { value: 'readableProfiles', text: 'Профили' },
      ],
    }
  },
  computed: {
    nosologies() {
      return this.pageData.map(n => ({
        ...n,
        readableProfiles: n.profiles.map(p => p.name).join(', '),
      }))
    },
    rowClickLink() {
      return `/${ADMIN}/${NOSOLOGIES}/${NOSOLOGIA_SHORT}`
    },
  },
}
</script>
